/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fonts.com
*/

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=1c21d903-645d-46f0-94b5-bc1f120362b6&fontids=5760841,5760861");
@font-face{
    font-family:"Helvetica Now Reg";
    src:url("../Fonts/5760841/46dd84ea-bb96-46da-973b-d7fcca46437e.woff2") format("woff2"),url("../Fonts/5760841/0fb90058-c064-40ed-8109-d1fd8633caa2.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Now Bold";
    src:url("../Fonts/5760861/39ae88e8-7d53-4395-8f9d-f7a0d4060ec4.woff2") format("woff2"),url("../Fonts/5760861/0c4f3bb0-d605-4462-9603-9163a560a536.woff") format("woff");
}

body{
    background-color: black;
    color: white;
}

* {
    font-family:"Helvetica Now Reg";
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    display: none;
}

.brLine {
    width: 90%;
    height: 2px;
    background-color: white;
    margin: 30px 0;
}

.navLeft {
    display: flex;

    .icon {
        max-width: 60px;
        border-radius: 30px;
    }
}

.slideshowphone{
    display: none;
}


.fadeimg {
    max-height: 800px;
    width: auto;
    padding: 0 10px;
}

.home{
    margin-bottom: 50px;

    .homelogo{
        width: 50%;
        margin: 10px 0;
    }

    .homelogotm{
        max-width: 15px;
        margin: 0 2px;
    }

    .homeText {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.6;
        font-size: 18px;
    }
}

.about {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100vw;
    align-items: center;
    padding: 20px 0;
    line-height: 1.6;

    .aboutHeader {
        margin: 10px 0;
        font-size: 20px;
        font-family:"Helvetica Now Bold";
    }

    .about1 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;

        .about1imgbox {
            width: 30%;
            max-height: 500px;
            overflow: hidden;

            .about1img {
                max-width: 100%;
            }
            
        }

        .about1text {
            max-width: 40%;
            font-size: 18px;
            text-align: flex-start;
        }
    }

    .about2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;

        .about2imgbox {
            width: 30%;
            max-height: 600px;
            overflow: hidden;

            .about2img {
                max-width: 100%;
            }
            
        }

        .about2text {
            max-width: 40%;
            min-width: 380px;
            font-size: 20px;

            .about2p{
                text-align: left;

                .quote {
                    font-size: 28px;
                    text-align: left;
                    font-family:"Helvetica Now Bold"
                }
            }
        }
    }
}

.gallerypage{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;

    h2{
        font-family:"Helvetica Now Bold";
    }
    

    .artistHeader{
        margin: 10px 0;
        font-size: 30px;
        font-family:"Helvetica Now Bold";
    }

    .artists{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    
        .artistbox{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 33%;
            
            .imgbox{
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                height: 320px;
        
                img{
                    width: 280px;
                }
        
            }
    
            p{
                margin: 20px 0;
                font-family:"Helvetica Now Bold";
            }
        }
    }
    
    .gallery {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    
        .imageWrap {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
            width: 100%;
            
            .eachImage {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                margin: 10px 0;
                overflow: hidden;
                height: 380px;
                
                .image {
                    width: 380px;
                }
            }
        }
        
    }
}

.services{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100vw;
    align-items: center;
    padding: 20px 0;
    line-height: 1.6;
    text-align: left;

    .servicesHeader{
        margin: 10px 0;
        font-size: 20px;
        font-family:"Helvetica Now Bold";
    }

    .servicesHeaderImg{
        width: 40%;
        height: auto;
        margin: 50px;
    }

    .servicesColor{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        margin: 50px 0;

        .colorImgBox{
            width: 40%;
            overflow: hidden;

            .servicesColorImg{
                width: 95%;
            }
        }

        .colorTextBox{
            max-width: 40%;
            font-size: 18px;
            text-align: flex-start;

            h2{
                font-family:"Helvetica Now Bold";
                color: rgb(85, 85, 85);
            }

            .colorLabel{
                font-family:"Helvetica Now Bold";
            }
        }
    }

    .servicesStyle{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        margin: 50px 0;


        .stylingImgBox{
            width: 40%;
            overflow: hidden;

            .servicesStylingImg{
                width: 100%;
            }
        }

        .stylingTextBox{
            max-width: 40%;
            font-size: 18px;
            text-align: flex-start;

            h2{
                color: rgb(85, 85, 85);
                font-family:"Helvetica Now Bold";
            }

            .stylingLabel{
                font-family:"Helvetica Now Bold";
            }
        }
    }

    button{
        border-radius: 5px;
        background: #000;
        padding: 10px 15px;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        margin-top: 20px;

        &:hover {
            transition: all 0.2s ease-in-out;
            background: #9f9f9f;
        }
    }

    .servicesFooterImg{
        width: 40%;
        height: auto;
        margin: 50px;
    }
}

.textpage{

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 8vw 20vh 8vw;
    text-align: left;
    font-family:"Helvetica Now";

    .textlogo{
        width: 50%;
        margin: 3vh 0;
    }

    h1{
        margin: 3vh 0;
    }

    p{
        width: 50%;
    }

    @media only screen and (max-width: 900px){
        .textlogo{
            width: 70%;
        }

        p{
            width: 90%;
        }
    }
}




@media only screen and (max-width: 900px) {
    .fadeimg {
        max-width: 400px;
        padding: 0;
    }

    .homelogo{
        min-width: 80%;
        padding-left: 20px;
    }

    .homelogotm{
        width: 2%;
    }

    .slideshowweb{
        display: none;
    }

    .slideshowphone{
        display: inline;
    }

    .about {
        .about1 {
            width: 90%;
            .about1text {
                min-width: 70%;
                margin: 30px 0;
            }
            .about1imgbox {
                width: 380px;
            }
        }

        .about2 {
            width: 90%;
            .about2text {
                min-width: 80%;
                margin: 30px 0;

            }
            .about2imgbox {
                width: 380px;
            }

            
        }
    
        .map {
            max-width: 350px;
            max-height: 350px;
        }
    }

    .artistbox{
        min-width: 380px;
    }

    .servicesHeaderImg, .servicesFooterImg{
        min-width: 90%;
        margin: 20px 0;
    }

    .colorImgBox, .stylingImgBox{
        height: 0px;
        width: 0px;
        display: hidden;
    }

    .colorTextBox, .stylingTextBox{
        min-width: 80%;
        margin: 10px 0;
    }
    
}

